import { createTheme } from "@mui/material";
import tinycolor from "tinycolor2";
import { setDefaultOptions } from "date-fns";
import locale from "date-fns/locale/en-NZ";

export const dateLocal = locale;
setDefaultOptions({ locale: locale });

declare module "@mui/material/styles" {
  interface Palette {
    calendar: {
      timeline: string;
      error: string;
      loading: string;
      available: {
        default: string;
        hover: string;
      };
      blocked: {
        default: string;
        hover: string;
      };
      unavailable: {
        default: string;
        hover: string;
      };
      booking: {
        contrastText: string;
        inPerson: string;
        video: string;
        phone: string;
      };
    };
    appointment: {
      upcomingText: string;
      currentText: string;
      upcomingBackground: string;
      currentBackground: string;
    };
  }
  interface PaletteOptions {
    calendar: {
      timeline: string;
      error: string;
      loading: string;
      available: {
        default: string;
        hover: string;
      };
      blocked: {
        default: string;
        hover: string;
      };
      unavailable: {
        default: string;
        hover: string;
      };
      booking: {
        contrastText: string;
        inPerson: string;
        video: string;
        phone: string;
      };
    };
    appointment: {
      upcomingText: string;
      currentText: string;
      upcomingBackground: string;
      currentBackground: string;
    };
  }
}

const availableSlotColor = "#F1FDF8";
const blockedSlotColor = "#C6CCE9";
const unavailableSlotColor = "#B1B6CB";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6020D4",
    },
    secondary: {
      main: "#0074B0",
      contrastText: "#fff",
    },
    background: {
      default: "#E5E5E5",
    },
    appointment: {
      upcomingText: "#FFFFFF",
      currentText: "#030822",
      upcomingBackground: "#6020D4",
      currentBackground: "#F98922",
    },
    calendar: {
      timeline: "#F34258",
      error: "#D22816",
      loading: "#F2F4FE",
      available: {
        default: availableSlotColor,
        hover: tinycolor(availableSlotColor).darken().toString(),
      },
      blocked: {
        default: blockedSlotColor,
        hover: tinycolor(blockedSlotColor).darken().toString(),
      },
      unavailable: {
        default: unavailableSlotColor,
        hover: tinycolor(unavailableSlotColor).darken().toString(),
      },
      booking: {
        contrastText: "#fff",
        inPerson: "linear-gradient(90deg, #7739DA 0%, #561DCF 100%)",
        video: "linear-gradient(90deg, #6FEFBD 0%, #29C88B 100%)",
        phone: "linear-gradient(90deg, #43BFF3 0%, #0095D4 100%)",
      },
    },
  },
});

export const OnboardingImageUrl =
  "https://images.unsplash.com/photo-1601342630314-8427c38bf5e6?auto=format&w=1024&h=768";
export const ProductName = "Ignite Provider Portal";
export const LogoUrl = "/logo.svg";
export const supportEmail = "hello@ignite.org.nz";
export const sessionLength = "01:00:00";

export default theme;
