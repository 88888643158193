import { Divider, Paper, Skeleton, Typography } from "@mui/material";
import { weekStart, zeroPad } from "../../utils";
import { addDays, eachDayOfInterval, format } from "date-fns";
import { useCalendarConfig } from "../../services/api";
import { Duration, WorkingHours } from "../../services/types";
import { sessionLength } from "../../theme";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { WorkingDay } from "./WorkingDay";
import { LoadingButton } from "@mui/lab";

const days = eachDayOfInterval({
  start: weekStart,
  end: addDays(weekStart, 6),
});

const dateToTime = (date: Date | null) => {
  if (!date) {
    return "";
  }
  return `${zeroPad(date.getHours())}:${zeroPad(date.getMinutes())}:${zeroPad(
    date.getSeconds()
  )}`;
};

const timeToDate = (time: string) => {
  if (!time) {
    return null;
  }
  return new Date("2020-01-01T" + time);
};

const nineToFive = {
  start: timeToDate("09:00:00")!,
  end: timeToDate("17:00:00")!,
};

export type AvailabilityProps = {
  availability: {
    day: number;
    dayName: string;
    isAvailable: boolean;
    hours: Partial<Duration>[];
  }[];
};

const Editor = ({ availability }: { availability: AvailabilityProps }) => {
  const {
    formState: { isDirty },
    getValues,
    reset,
    control,
    handleSubmit,
  } = useForm<AvailabilityProps>({
    defaultValues: availability,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { setWorkingHours, isSettingWorkHours } = useCalendarConfig();

  const saveChanges = async (values: AvailabilityProps) => {
    const workingHours: WorkingHours[] = values.availability
      .filter((day) => day.isAvailable)
      .flatMap((day) =>
        day.hours.map((h) => ({
          dayOfWeek: day.day,
          startTime: dateToTime(h.start!),
          endTime: dateToTime(h.end!),
          sessionLength,
        }))
      );

    // TODO: Let the user set their time zone
    // Get browser time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await setWorkingHours({ workingHours, timeZone });
    reset(values);
    enqueueSnackbar("Your availablity has been updated", {
      variant: "success",
    });
  };

  return (
    <form onSubmit={handleSubmit(saveChanges)}>
      {availability.availability.map((a, i) => (
        <WorkingDay
          key={i}
          day={a.day}
          dayName={a.dayName}
          control={control}
          getValues={getValues}
        />
      ))}
      <LoadingButton
        type="submit"
        variant="outlined"
        color="secondary"
        fullWidth
        loading={isSettingWorkHours}
        disabled={!isDirty}
        sx={{ mt: 2 }}
      >
        Save Changes
      </LoadingButton>
    </form>
  );
};

export const AvailabilityHours = () => {
  const { config, isLoading } = useCalendarConfig();

  const getWorkingHours = (index: number, workingHours?: WorkingHours[]) => {
    const matchedDays = workingHours?.filter((h) => h.dayOfWeek === index);
    if(workingHours?.length === 0){
      return [nineToFive];// Return an array with the default nineToFive if no matches
    }
    if (!matchedDays || matchedDays.length === 0) {
      return []; 
    } else {
      return matchedDays.map((h) => ({
        start: timeToDate(h.startTime)!,
        end: timeToDate(h.endTime)!,
      }));
    }
  };

  const availability: AvailabilityProps = {
    availability: days.map((d, i) => {
      const hoursForDay = getWorkingHours(i, config?.workingHours);
      return {
        day: i,
        dayName: format(d, "EEEE"),
        isAvailable: hoursForDay.length > 0,
        hours: hoursForDay,
      };
    }),
  };

  return (
    <Paper sx={{ p: 2, flex: 1, width: "100%" }}>
      <Typography gutterBottom variant="h6" component="div">
        Default booking availability
      </Typography>
      <Divider />
      {isLoading ? (
        <Skeleton
          width={"100%"}
          height={1122}
          sx={{ margin: "16px 0px", transform: "none" }}
          variant="rounded"
          animation="wave"
        />
      ) : (
        <Editor availability={availability} />
      )}
    </Paper>
  );
};
