import useSWR from "swr";
import { Specialties,Domains } from "../types";
import { fetcher } from "./fetcher";

export const useSpecialities = () => {
  const { data, isLoading } = useSWR<{specialties: Specialties, domains: Domains}>('speciality/SpecialitiesAndDomains', fetcher);

  return {
    specialities: data?.specialties, 
    domains: data?.domains, 
    isLoading
  };
};
