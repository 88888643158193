import { Stack } from "@mui/material";
import { AvailabilityHours } from "./AvailablityHours";
import { ScheduleManagement } from "./ScheduleManagement";
import { Helmet } from "react-helmet";

export const Availability = () => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      alignItems="flex-start"
      spacing={2}
    >
      <Helmet>
        <title>My Availablity</title>
      </Helmet>
      <AvailabilityHours />
      <Stack spacing={2} flex={1}>
        <ScheduleManagement />
      </Stack>
    </Stack>
  );
};
