import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Stack,
  Avatar,
  TextField,
  IconButton,
  Box,
  Button,
  Typography,
  Chip,
  Grid,
  CircularProgress,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { Profile } from "../../services/types";
import DomainChip from "../domains/DomainChip";
import { useSpecialities } from "../../services/api";
import {
  useFeatureFlags,
  FeatureFlag,
} from "../../services/featureFlagsProvider";

interface EditProfileProps {
  provider: Profile;
  onSave: (formData: FormData) => void;
  selectedSpecialties: string[];
  selectedDomains: string[];
  handleCancel: () => void;
}

interface EditProfileFormData extends Profile {
  avatarFile?: File;
  selectedSpecialties: string[];
  selectedDomains: string[];
}

const EditProfile: React.FC<EditProfileProps> = ({
  provider,
  onSave,
  selectedSpecialties: initialSelectedSpecialties,
  selectedDomains: initialSelectedDomains,
  handleCancel,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<EditProfileFormData>({
    defaultValues: {
      providerId: provider.providerId,
      firstName: provider.firstName || "",
      lastName: provider.lastName || "",
      country: provider.country || "",
      city: provider.city || "",
      postCode: provider.postCode || "",
      address: provider.address || "",
      address2: provider.address2 || "",
      biography: provider.biography || "",
      suburb: provider.suburb || "",
      qualificationsExtended: provider.qualificationsExtended || "",
      qualifications: provider.qualifications || "",
      shortDescription: provider.shortDescription || "",
      selectedSpecialties: initialSelectedSpecialties || [],
      selectedDomains: initialSelectedDomains || [],
      sessionLeadTime: provider.sessionLeadTime || "",
      bufferTime: provider.bufferTime || ""
    },
  });
  const featureFlags = useFeatureFlags();
  const domainsofwellbeingEnabled = featureFlags?.some(
    (flag: FeatureFlag) => flag.name === "domainsofwellbeing" && flag.enabled
  );
  const [qualificationsExtendedCount, setQualificationsExtendedCount] =
    useState(provider.qualificationsExtended?.length || 0);
  const [shortDescriptionCount, setShortDescriptionCount] = useState(
    provider.shortDescription?.length || 0
  );
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "qualificationsExtended") {
        setQualificationsExtendedCount(
          value.qualificationsExtended?.length || 0
        );
      } else if (name === "shortDescription") {
        setShortDescriptionCount(value.shortDescription?.length || 0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // eslint-disable-next-line
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { specialities, domains, isLoading } = useSpecialities();

  const flattenedDomains = domains
    ? Object.keys(domains).reduce<string[]>((acc, category) => {
        // Concatenate the list of domains for each category to the accumulator
        return acc.concat(domains[category]);
      }, [])
    : [];

  const sortedSpecialities = specialities
    ? Object.values(specialities).sort()
    : [];
  const [avatarUrl, setAvatarUrl] = useState<string>(provider.avatar || "");
  const handleSpecialtyClick = (specialty: string) => {
    const currentSpecialties = getValues("selectedSpecialties") || []; // Ensure it's always an array
    const isAlreadySelected = currentSpecialties.includes(specialty);

    const updatedSpecialties = isAlreadySelected
      ? currentSpecialties.filter((item) => item !== specialty)
      : [...currentSpecialties, specialty];
    setValue("selectedSpecialties", updatedSpecialties, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleDomainClick = (domain: string) => {
    const currentDomains = getValues("selectedDomains") || []; // Ensure it's always an array
    const isAlreadySelected = currentDomains.includes(domain);

    const updatedDomains = isAlreadySelected
      ? currentDomains.filter((item) => item !== domain)
      : [...currentDomains, domain];
    setValue("selectedDomains", updatedDomains, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (provider.avatar) {
      setAvatarUrl(provider.avatar);
    }
  }, [provider.avatar]);
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      setValue("avatarFile", file);
      setAvatarUrl(URL.createObjectURL(file));
    } else {
      setSelectedFile(null);
      setAvatarUrl("");
    }
  };
  const onSubmit = (formData: EditProfileFormData) => {
    const submissionData = new FormData();
    Object.keys(formData).forEach((key) => {
      const value = formData[key as keyof EditProfileFormData];
      if (key === "avatarFile" && value instanceof File) {
        submissionData.append("avatar", value); // Append file as 'avatar'
      } else if (Array.isArray(value)) {
        // Append each item in the array separately
        value.forEach((item) => submissionData.append(`${key}[]`, item));
      } else if (value !== null && value !== undefined) {
        // Append non-array, non-null values
        submissionData.append(key, value.toString());
      }
    });

    onSave(submissionData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="start">
        <Grid item xs={12} style={{ textAlign: "right", marginTop: "10px" }}>
          <Button
            variant="outlined"
            sx={{ color: "red", borderColor: "red", mr: 1 }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button type="submit" variant="outlined">
            Save
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={2}>
          <Box position="relative" sx={{ display: "inline-block" }}>
            <Avatar src={avatarUrl} sx={{ width: 100, height: 100 }} />
            <IconButton
              sx={{
                position: "absolute",
                bottom: -10,
                right: -10,
                backgroundColor: "white",
                border: "2px solid white",
              }}
              component="label"
            >
              <input type="file" hidden onChange={handleAvatarChange} />
              <PhotoCamera />
            </IconButton>
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={5}>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: "First Name is required",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4} lg={5}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="address"
            rules={{ required: "Address is required" }}
            control={control}
            render={({ field }) => (
              <TextField
                required
                {...field}
                fullWidth
                label="Address Line "
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Address Line 2"
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        {/* Postcode */}
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="postCode"
            control={control}
            rules={{ required: "Postcode is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Postcode"
                error={!!errors.postCode}
                helperText={errors.postCode?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        {/* Suburb */}
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="suburb"
            control={control}
            rules={{ required: "Suburb is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Suburb"
                error={!!errors.suburb}
                helperText={errors.suburb?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="city"
            control={control}
            rules={{ required: "City is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="City"
                error={!!errors.city}
                helperText={errors.city?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={12} sm={6} lg={6}>
          <Controller
            name="country"
            control={control}
            rules={{ required: "Country is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Country"
                error={!!errors.country}
                helperText={errors.country?.message}
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom marginTop={2}>
            Specialties
          </Typography>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={2}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Stack direction="row" spacing={0} marginBottom={2} flexWrap="wrap">
              {sortedSpecialities.map((specialty) => (
                <Chip
                  key={specialty}
                  label={specialty}
                  onClick={() => handleSpecialtyClick(specialty)}
                  color={
                    getValues("selectedSpecialties").includes(specialty)
                      ? "primary"
                      : "default"
                  }
                  style={{
                    borderRadius: "4px",
                    marginRight: "8px",
                    marginBottom: "8px",
                    boxShadow: getValues("selectedSpecialties").includes(
                      specialty
                    )
                      ? "0px 2px 4px rgba(0,0,0,0.2)"
                      : undefined,
                    fontWeight: getValues("selectedSpecialties").includes(
                      specialty
                    )
                      ? "bold"
                      : undefined,
                  }}
                  variant="outlined"
                />
              ))}
            </Stack>
          )}
        </Grid>
        {domainsofwellbeingEnabled && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom marginTop={2}>
              Domains
            </Typography>
            <Stack direction="row" spacing={0} marginBottom={2} flexWrap="wrap">
              {flattenedDomains
                ? flattenedDomains.map((domain) => (
                    <DomainChip
                      key={domain}
                      label={domain}
                      onClick={() => handleDomainClick(domain)}
                      color={
                        getValues("selectedDomains").includes(domain)
                          ? "primary"
                          : "default"
                      }
                      style={{
                        borderRadius: "4px",
                        marginRight: "8px",
                        marginBottom: "8px",
                        boxShadow: getValues("selectedDomains").includes(domain)
                          ? "0px 2px 4px rgba(0,0,0,0.2)"
                          : undefined,
                        fontWeight: getValues("selectedDomains").includes(
                          domain
                        )
                          ? "bold"
                          : undefined,
                      }}
                      variant="outlined"
                    />
                  ))
                : null}
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="shortDescription"
            control={control}
            rules={{ required: "Short description is required" }}
            render={({ field }) => (
              <div>
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Short Description"
                  error={!!errors.shortDescription}
                  helperText={errors.shortDescription?.message}
                  inputProps={{ maxLength: 250 }}
                />
                <Typography variant="caption" color="textSecondary">
                  {shortDescriptionCount}/250
                </Typography>
              </div>
            )}
          />
        </Grid>

        {/* Biography */}
        <Grid item xs={12}>
          <Controller
            name="biography"
            control={control}
            rules={{ required: "Biography is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                label="Biography"
                error={!!errors.biography}
                helperText={errors.biography?.message}
              />
            )}
          />
        </Grid>

        {/* Qualifications Extended */}
        <Grid item xs={12}>
          <Controller
            name="qualificationsExtended"
            control={control}
            rules={{ required: "Qualifications are required" }}
            render={({ field }) => (
              <div>
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Qualifications"
                  error={!!errors.qualificationsExtended}
                  helperText={errors.qualificationsExtended?.message}
                  inputProps={{ maxLength: 500 }}
                />
                <Typography variant="caption" color="textSecondary">
                  {qualificationsExtendedCount}/500
                </Typography>
              </div>
            )}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EditProfile;
