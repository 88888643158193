// FeatureFlagsContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

// Define a type or interface for your Feature Flags
export interface FeatureFlag {
  name: string;
  enabled: boolean;
}
const FeatureFlagsContext = createContext<FeatureFlag[] | null>(null);

export function useFeatureFlags() {
  return useContext(FeatureFlagsContext);
}

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const environment = process.env.REACT_APP_ENV;
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[] | null>(null);
  useEffect(() => {
    fetch(`/api/FeatureFlags?environment=${environment}`)
      .then((response) => response.json())
      .then((data) => {
        const transformedFlags = Object.keys(data).map((key) => ({
          name: key,
          enabled: data[key],
        }));
        setFeatureFlags(transformedFlags);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [environment]);

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}
