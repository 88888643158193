import useSWR from "swr";
import { Profile, UpdateProfileData } from "../types";
import { fetcher } from "./fetcher";
import axios from "axios";
import useSWRMutation from "swr/mutation";

export const useProfile = () => {
  const { data, isLoading, mutate } = useSWR<Profile>("provider/profile", fetcher);

  const { trigger: updateProviderProfile, isMutating: isUpdatingProviderProfile } =  useSWRMutation(
    "/provider/profile",
    (url: string, { arg }: { arg: UpdateProfileData }) =>
      axios.putForm(url, arg).then(() => mutate())
  );
  
  return {
    provider: data,
    isLoading,
    updateProviderProfile,
    isUpdatingProviderProfile
  };
};


export const mapToProviderToUpdateProvider = (source: Profile | undefined): UpdateProfileData => {
  return {
    address: source!.address,
    address2: source!.address2,
    Biography: source!.biography,
    BufferTime: source!.bufferTime,
    city: source!.city,
    country: source!.country,
    FirstName: source!.firstName,
    LastName: source!.lastName,
    postCode: source!.postCode,
    providerId: source!.providerId,
    Qualifications: source!.qualifications,
    QualificationsExtended: source!.qualificationsExtended,
    SelectedDomains: source!.domains,
    SelectedSpecialties: source!.specialties,
    SessionLeadTime: source!.sessionLeadTime,
    ShortDescription: source!.shortDescription,
    suburb: source!.suburb
  } as UpdateProfileData;
};