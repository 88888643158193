import React, { useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useProfile } from "../../services/api";
import { useSnackbar } from "notistack";
import { MapPinIcon } from "@heroicons/react/24/outline";
import EditProfile from "./EditProfile";
import DomainChip from "../domains/DomainChip";
import { mutate } from "swr";
import {
  useFeatureFlags,
  FeatureFlag,
} from "../../services/featureFlagsProvider";
export function MyServices() {
  const { provider, isLoading: isFetching } = useProfile();
  const [isUpdating, setIsUpdating] = useState(false);
  const isLoading = isFetching || isUpdating;
  const [showMore, setShowMore] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isEditMode, setIsEditMode] = useState(false);
  const specialties = provider?.specialties ?? [];
  const domains = provider?.domains ?? [];
  const { enqueueSnackbar } = useSnackbar();
  const displayedSpecialties =
    isSmallScreen && !showMore ? specialties.slice(0, 6) : specialties;
  const featureFlags = useFeatureFlags();
  const isEditProfileEnabled = featureFlags?.some(
    (flag: FeatureFlag) => flag.name === "providerEdit" && flag.enabled
  );
  const domainsofwellbeingEnabled = featureFlags?.some(
    (flag: FeatureFlag) => flag.name === "domainsofwellbeing" && flag.enabled
  );
  const handleSave = async (formData: FormData) => {
    setIsUpdating(true);
    try {
      await fetch("/api/provider/profile", {
        method: "PUT",
        body: formData,
      });
      await mutate("provider/profile", undefined, true);
      enqueueSnackbar("Save Complete!", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
    }
    setIsUpdating(false);
    setIsEditMode(false);
  };
  const handleCancel = () => {
    setIsEditMode(false);
  };
  return (
    <Paper sx={{ p: 2, flex: 1, width: "100%" }}>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Typography gutterBottom variant="h6" component="div">
            My Services
          </Typography>
          <Divider />
          {isEditProfileEnabled && !isEditMode && (
            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <Button variant="outlined" onClick={() => setIsEditMode(true)}>
                Edit
              </Button>
            </div>
          )}
          {isEditMode ? (
            <EditProfile
              provider={provider!}
              onSave={handleSave}
              selectedSpecialties={specialties}
              selectedDomains={domains}
              handleCancel={handleCancel}
            />
          ) : (
            <div>
              <Stack direction="row" alignItems="center" spacing={2} my={2}>
                <Avatar
                  src={provider?.avatar}
                  sx={{ width: 100, height: 100 }}
                />
                <div>
                  <Typography variant="h5">
                    {provider?.firstName + " " + provider?.lastName}
                  </Typography>
                  <Typography>
                    {provider?.type} | {provider?.qualifications}
                  </Typography>
                  <Typography>
                    <MapPinIcon
                      className="icon"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    {provider?.address}
                  </Typography>
                </div>
              </Stack>
              <Typography my={2}>{provider?.shortDescription}</Typography>
              <Divider />
              <Typography variant="subtitle1" gutterBottom marginTop={2}>
                Specialties
              </Typography>
              <Stack direction="row" marginBottom={2} flexWrap="wrap">
                {displayedSpecialties.map((s, index) => (
                  <Chip
                    key={s}
                    label={s}
                    sx={{
                      borderRadius: 2,
                      marginRight: "8px",
                      marginBottom: "8px",
                    }}
                    variant="outlined"
                  />
                ))}
              </Stack>
              {isSmallScreen && (provider?.specialties?.length ?? 0) > 6 && (
                <Button onClick={() => setShowMore(!showMore)}>
                  {showMore ? "Show Less" : "Show More"}
                </Button>
              )}

              <Divider />
              {domainsofwellbeingEnabled && (
                <>
                  <Typography variant="subtitle1" gutterBottom mt={2}>
                    Domains
                  </Typography>
                  <Stack direction="row" marginBottom={2} flexWrap="wrap">
                    {domains.map((s, index) => (
                      <DomainChip key={s} label={s} />
                    ))}
                  </Stack>
                  <Divider />
                </>
              )}

              <Typography variant="subtitle1" gutterBottom mt={2}>
                Biography
              </Typography>
              <Typography variant="body1" mb={2}>
                {provider?.biography}
              </Typography>
              <Divider />
              <Typography variant="subtitle1" gutterBottom mt={2}>
                Qualifications
              </Typography>
              <Typography variant="body1" mb={2}>
                {provider?.qualificationsExtended}
              </Typography>
            </div>
          )}

          <Divider />
        </div>
      )}
    </Paper>
  );
}
