import React from "react";
import Chip, { ChipProps } from "@mui/material/Chip";

import { DomainIconMap } from "./DomainIconMap";

interface DomainChipProps extends ChipProps {
  label: keyof typeof DomainIconMap;
}

// Define the DomainChip component
const DomainChip: React.FC<DomainChipProps> = ({ label, style, ...props }) => {
  const IconComponent = DomainIconMap[label];
  const combinedStyles = {
    borderRadius: "4px", // your default style
    marginRight: "8px",
    marginBottom: "8px",
    ...style, // this will override the default styles if provided
  };
  return (
    <Chip
      {...(IconComponent ? { icon: <IconComponent /> } : {})}
      label={label}
      sx={combinedStyles}
      variant="outlined"
      {...props}
    />
  );
};

export default DomainChip;
