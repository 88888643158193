import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import theme, { ProductName, dateLocal } from "./theme";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { FeatureFlagsProvider } from "./services/featureFlagsProvider";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <FeatureFlagsProvider>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocal}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider>
            <Helmet
              defaultTitle={ProductName}
              titleTemplate={ProductName + " - %s"}
            >
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`}
              />
              <script>
                {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GA_ID}');`}
              </script>
            </Helmet>
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </FeatureFlagsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
